export enum ROUTES_PATH {
  SignIn = 'sign-in',
  Dashboard = 'inbox',

  ValidatorsDashboard = 'Dashboard',

  ValidatorsScreen = 'validation',

  ReporstDashboard = 'reports',
  AddRequestProcedure = 'procedure10/request/add',
  ClarificationOrReposition = 'procedure10/request/add/clarificationRequest',
  SearchRequest = 'procedure10/request/add/searchRequest',
  EditRequest = 'procedure10/request/add/edit-request',
  ValidationRequestProcedure = 'procedure10/request/validation',
  CoordinatorRequestProcedure = 'procedure10/request/coordinator',
  SubdirectorRequestProcedure = 'procedure10/request/subdirector',


  //ValidationRequestProcedure16 = 'procedure16/request/validation/:request_id',
  //SpecialistRequestProcedure16 = 'procedure16/request/specialist/:request_id',
  //ViewRequest = 'procedure16/request/edit/:request_id',
  //Revisar Advance Line
  //RevViewRequest = '/procedure16/request/edit/',
  //RevValidationRequestProcedure16 = '/procedure16/request/validation/',
  //ReplenishmentResource = 'replenishment-resource/:numero/:tramite',
}
