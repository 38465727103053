import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { Request } from '@models';
import { BehaviorSubject, Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RequestService {

  requestNumRadicado = new BehaviorSubject(null);
  requestNumRadicado$ = this.requestNumRadicado.asObservable();

  requestType = new BehaviorSubject(null);
  requestType$ = this.requestType.asObservable();

  requestLicenceNumber = new BehaviorSubject(null);
  requestLicenceNumber$ = this.requestLicenceNumber.asObservable();

  requestDateInhumacion = new BehaviorSubject(null);
  requestDateInhumacion$ = this.requestLicenceNumber.asObservable();

  requestSelected = new BehaviorSubject(null);
  requestSelected$ = this.requestSelected.asObservable();

  getRequest = new BehaviorSubject(false);
  getRequest$ = this.getRequest.asObservable();

  viewRequest = new BehaviorSubject(false);
  viewRequest$ = this.viewRequest.asObservable();

  constructor(private http: HttpClient) {
  }

  //API URL BACK

  saveRequest(json: any): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrlBack }/api/Request/SaveRequest`, json);
  }

  updateDeceased(json: any): Observable<any> {
    return this.http.put<any>(`${ environment.apiUrlBack }/api/Deceased/UpdateDeceased`, json);
  }

  saveDeceased(json: any): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrlBack }/api/Deceased/SaveDeceasedInformation`, json);
  }

  getRequestById(id): Observable<any> {
    return this.http.get<Request>(`${ environment.apiUrlBack }/api/Request/GetRequestByIdRequest/${ id }`);
  }

  /**
   * Obtiene las solicitudes realizadas por un usuario dado su ID
   */
  public getRequestsByIdUserExternal(idUser: string): Observable<any> {
    return this.http.get(`${ environment.apiUrlBack }/api/Request/GetRequestsByIdUserExternal/${idUser}`);
  }

  /**
   * Obtiene el seguimiento de una solicitud dado su idrequest
   */
  public getTracking(idrequest: string): Observable<any> {
    return this.http.get(`${ environment.apiUrlBack }/api/RequestHistory/GetRequestHistory/${idrequest}`);
  }
  /**
   * Obtiene las solicitudes por rol
   */
  getRequestsByRol(idrol: any,filter:string,search:string,pagenumber:any,pagesise:any): Observable<any> {
    let url = `${idrol}?search=${search}&filter=${filter}&page=${pagenumber}&pageSize=${pagesise}`;
    return this.http.get(`${ environment.apiUrlBack }/api/Request/GetRequestsByIdRol/${url}`);
  }

  /**
   * actualiza el validador d ela solicitud
   */
  assignRequest(json: any): Observable<any> {
    return this.http.put<any>(`${ environment.apiUrlBack }/api/Request/UpdateUserInternal`, json);
  }

  /**
   * Obtiene las solicitudes por rol
   */
  getReports(data: any): Observable<any> {
    return this.http.post(`${ environment.apiUrlBack }/api/Request/GetRequestsByInitialDateAndFinalDate`,data);
  }

  /**
   * Obtiene si existe un duplicado para una licencia de ihnumacion
   */
  public getDuplicatedInhumacion(number: string,date:string): Observable<any> {
    return this.http.get(`${ environment.apiUrlBack }/api/ExhumationLicense/ValidateExhumationLicense/${number}/${date}`);
  }

  /**
   * Obtiene el path del blob storage de una Licencia de Exhumación
   * @param idRequest Id de la solicitud
   */
  public getExhuationLicenseByIdRequest(idRequest: string): Observable<any> {
    return this.http.get(`${ environment.apiUrlBack }/api/ExhumationLicense/GetExhumationLicensePathByIdRequest/${idRequest}`);
  }


  /**
   * Obtiene el rol de un usuario dado su oid del b2c
   * @param oid Id dado por el b2c del usuario a buscar
   */
  getRoleByIdUser(oid: string) : Observable<any> {
    return this.http.get(`${environment.TRAMITE_SECURITY_URL}/GetRoleByIdUser/${oid}`);
  }
  /**
   * Obtiene la informacion de un usuario dado su codigo de ventanilla
   * @param idCode Codigo de ventanilla a buscar
   */
  public getInfoUserByIdCodeVentanilla(idCode: any): Observable<any> {
    return this.http.get(`${environment.TRAMITE_SHARED_URL}/v2/Persona/GetInfoUserById/${idCode}`);
  }
  /**
   * Obtiene codigo de ventanilla de un usuario dado su oid del B2C
   * @param oidToken_usuario Id dado por el b2c
   */
  public getCodeVentanillaByIdUser(oidToken_usuario: string) : Observable<any> {
    return this.http.get(`${environment.TRAMITE_SECURITY_URL}/GetCodeVentanillaByIdUser/${oidToken_usuario}`);
  }
  /**
   * Obtiene lista de Tipos de Identificacion
   */
  public getIdentificationType(): Observable<any>{
    return this.http.get(`${environment.TRAMITE_SHARED_URL}/v1/TipoIdentificacion/GetTipoIdentificacion`);
  }






}
