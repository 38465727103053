
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NomenclatorService {

  setNomenclature = new BehaviorSubject(null);
  getNomenclature$ = this.setNomenclature.asObservable();


}
