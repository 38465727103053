import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

import { environment } from "../../../environments/environment";

const { TRAMITE_SECURITY_URL } = environment;

/**
 * Service para la API de SECURITY del API MANAGER de la SDS
 */
@Injectable({
  providedIn: 'root'
})
export class TramiteSecurityService {

  constructor(private http: HttpClient) { }

  /**
   * Obtiene el codigo de ventanilla de una persona dado el idToken o sub
   * @param idToken IdToken o sub
   */
  public getCodeVentanillaByIdUser(idToken) : Observable<any> {
    return this.http.get(`${TRAMITE_SECURITY_URL}/GetCodeVentanillaByIdUser/${idToken}`);
  }
}
