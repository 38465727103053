<div class="block block--gov-accessibility">
  <div class="block-options navbar-expanded">
    <a class="contrast-ref" (click)="moveContrast()">
      <span class="govco-icon govco-icon-contrast-n"></span>
      <label> Contraste </label>
    </a>
    <a class="min-fontsize" (click)="down()">
      <span class="govco-icon govco-icon-less-size-n"></span>
      <label class="align-middle"> Reducir letra </label>
    </a>
    <a class="max-fontsize" (click)="up()">
      <span class="govco-icon govco-icon-more-size-n"></span>
      <label class="align-middle"> Aumentar letra </label>
    </a>
  </div>
</div>
