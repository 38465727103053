<nav class="navbar fixed-top navbar-expand-lg navbar-gov-co">
  <div class="navbar-gov-co-pri container-fluid">
    <div class="navbar-logo float-left">
      <a class="navbar-brand" href="https://www.gov.co/" target="_blank" rel="noopener">
        <img src="https://cdn.www.gov.co/assets/images/logo.png" height="30" alt="Logo Gov.co" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapsible"
        aria-controls="navbarCollapsible"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse navbar-first-menu float-right">
      <div class="nav-primary mx-auto">
        <ul class="navbar-nav ml-auto nav-items nav-items-desktop"></ul>
      </div>

    </div>

    <!-- user-->
    <div class="nav-item-primary ml-auto mr-2 is-scroll" *ngIf="currentUser">
      <div class="d-flex" style="color: white; font-weight: bold; padding: 5px">
        <img class="default-img-profile"
             src="./assets/icons/default_user.jpg" alt="User">
          <span class="profile-user-name"> {{ currentUser?.name || (currentUser?.family_name + ' ' + currentUser?.given_name) }}
          </span>
          <span class="govco-icon govco-icon-right-arrow-n lang-govco ml-3 btn-logout"
                title="Cerrar Sesión"
                (click)="setLogout()"></span>
      </div>
    </div>


  </div>

</nav>

<div class="navbar-gov-co-search">
  <div class="content-logo" style="background-color: #FFFFFF!important;">

    <img src="./assets/images/logoSDS.png" alt="Secretaria Distrital de Salud">

  </div>
</div>
