import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable } from 'rxjs';
import { PopupService } from 'src/app/core/services/popup.service';
import Swal from "sweetalert2";

const { PROCEDURE_BLOB_URI } = environment;

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  listRequirements = new BehaviorSubject<any[]>(null);
  listRequirements$ = this.listRequirements.asObservable();

  listDocuments = new BehaviorSubject(null);
  listDocuments$ = this.listDocuments.asObservable();

  listRevisionDocuments = new BehaviorSubject(null);
  listRevisionDocuments$ = this.listRevisionDocuments.asObservable();

  numDocsRequired = new BehaviorSubject(null);
  numDocsRequired$ = this.numDocsRequired.asObservable();

  numDocs = new BehaviorSubject(null);
  numDocs$ = this.listDocuments.asObservable();

  missingDocsRequired  = new BehaviorSubject(null);
  missingDocsRequired$ = this.missingDocsRequired.asObservable();

  constructor(private http: HttpClient, private popupAlert: PopupService) {
  }

  setListDocuments(document: any) {
    this.listDocuments.next(document);
  }

  setMissingDocsRequired(){
    this.missingDocsRequired.next(this.numDocs.value < this.numDocsRequired.value);
  }

  setNumDocsRequired(){
    this.numDocsRequired.next(0);
    if(this.listRequirements && this.listRequirements.value != null && this.listRequirements.value.length > 0){
      this.numDocsRequired.next((this.listRequirements.value.filter(requirement => requirement.required)).length);
    }
  }

  setNumDocs(){
    this.numDocs.next(0);
    if(this.listDocuments && this.listDocuments.value != null && this.listDocuments.value.length > 0){
      this.numDocs.next(this.listDocuments.value.length);
    }
  }

  saveDocumentsRequestBLOB(formData: any): Observable<any> {
    return this.http.post(`${environment.apiManager}/api/v1/Storage/AddFile`, formData);
  }

  saveDocumentsRequestDB(json: any): Observable<any> {
    return this.http.post(`${environment.apiUrlBack}/api/Document/SaveDocument`, json);
  }
  UpdateDocuments(json: any): Observable<any> {
    return this.http.put(`${environment.apiUrlBack}/api/Document/UpdateDocument`, json);
  }

  AddResolucion(json: any): Observable<any> {
    return this.http.post(`${environment.apiUrlBack}/api/Document/AddResolution`, json);
  }

  GenerateResolutionPDF(json: any): Observable<any> {
    return this.http.post(`${environment.apiUrlBack}/api/ExhumationLicense/GenerateExhumationLicense`, json);
  }



  /**
   * Permite visualizar un archivo en un iframe de una ventana actual
   * @param pathArchive Ruta del archivo
   */
  public viewArchiveActualWindow(pathArchive: string, fileBlob?: Blob): void {
    try
    {
      this.popupAlert.infoAlert('Cargando documento...', 4000);

      if (pathArchive == "") {
        window.open(window.URL.createObjectURL(fileBlob), '_blank');
      } else {
        const oidAndNameBlob: Array<string> = pathArchive.split("/")
        const contenedor: string = oidAndNameBlob[8]
        const oid: string = oidAndNameBlob[9]
        const nameBlob: string = oidAndNameBlob[10]

        this.http.get(`${PROCEDURE_BLOB_URI}/Storage/GetBlob/${contenedor}/${oid}/${nameBlob}`, { responseType: 'blob' })
          .subscribe((blob: Blob) => {
            let url = `${PROCEDURE_BLOB_URI}/Storage/GetBlob/${contenedor}/${oid}/${nameBlob}`;
            document.querySelector("iframe").src = url;
          });
      }

    } catch (e) {
      console.log(e)
      this.popupAlert.errorAlert(
        'Ocurrió un error al previsualizar el documento.',
        4000);
    }
  }

  /**
   * Permite visualizar un archivo en un pop up de sweet alert
   * @param pathArchive Ruta del archivo cuando existen en el contenedor blobStorage
   * @param fileBlob Blob a mostrar Cuando el archivo es temporal (para preeliminar)
   */
  public viewArchiveInPopUp(pathArchive: string, fileBlob?: Blob): void {
    try {
      this.popupAlert.infoAlert('Cargando documento...', 10000);

      if (pathArchive == "") {
        Swal.fire({
          title: 'Pdf',
          showCloseButton: true,
          confirmButtonText: 'Cerrar',
          confirmButtonColor: '#3366CC',
          html: `<iframe src='${window.URL.createObjectURL(fileBlob)}' width="1300" height="700" title="visualizacion-documento">`,
          width: '75%',
        });
      } else {
        const oidAndNameBlob: Array<string> = pathArchive.split("/")
        const contenedor: string = oidAndNameBlob[8]
        const oid: string = oidAndNameBlob[9]
        const nameBlob: string = oidAndNameBlob[10]

        this.http.get(`${PROCEDURE_BLOB_URI}/Storage/GetBlob/${contenedor}/${oid}/${nameBlob}`, { responseType: 'blob' })
          .subscribe((blob: Blob) => {
            let url = `${PROCEDURE_BLOB_URI}/Storage/GetBlob/${contenedor}/${oid}/${nameBlob}`;
            Swal.fire({
              title: 'Pdf',
              showCloseButton: true,
              confirmButtonText: 'Cerrar',
              confirmButtonColor: '#3366CC',
              html: `<iframe src='${url}' width="1300" height="700" title="visualizacion-documento">`,
              width: '75%',
            });

          });
      }

    } catch (e) {
      console.log(e)
      this.popupAlert.errorAlert(
        'Ocurrió un error al previsualizar el documento.',
        4000);
    }
  }

  convertBase64ToPdfFile(base64: string, filename: string): File {
    try {
      base64 = `data:application/pdf;base64,${base64}`;
      const base64Arr = base64.split(',');
      const mime = base64Arr[0].match(/:(.*?);/)[1];
      const bstr = atob(base64Arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } catch (e) {
      console.log("se daño el base64", e)
      return null;
    }
  }








}
