
import { Injectable } from '@angular/core';

import { Log } from '@models';


@Injectable({
  providedIn: 'root'
})
export class LogService {
  mock: Log[] = [
];

}
