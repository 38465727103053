import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RepresentativeService {
  representativeSource = new BehaviorSubject(null);
  representativeTarget$ = this.representativeSource.asObservable();

  constructor(private http: HttpClient) {
  }


}
