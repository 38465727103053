import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-accesibility',
  templateUrl: './accesibility.component.html',
  styleUrls: ['./accesibility.component.scss']
})
export class AccesibilityComponent implements OnInit {
  font: number = 14;
  colorBG: any;
  activeContrast: any = 0;

  constructor(@Inject(DOCUMENT) private document: Document,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.moveContrast();
  }

  up() {
    this.font = this.font === 100 ? this.font : this.font + 1;
    // @ts-ignore
    document.querySelector("html").style.fontSize = this.font + "px";
  }

  down() {
    this.font = this.font === 1 ? this.font : this.font - 1;
    // @ts-ignore
    document.querySelector("html").style.fontSize = this.font + "px";
  }

  moveContrast() {

    if (this.activeContrast == 1) {
      this.activeContrast = false;
      setTimeout(() => {
        this.renderer.addClass(this.document.body, 'body-cs');
      }, 200);
    } else {
      this.activeContrast = true;
      setTimeout(() => {
        this.renderer.removeClass(this.document.body, 'body-cs');
      }, 200);
    }
  }

}
