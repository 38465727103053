import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, lastValueFrom, Observable, of, switchMap} from 'rxjs';
import { AuthModel, SSOAuthResponse } from '@models';
import { map } from "rxjs/operators";
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { ROUTES_PATH } from "@enums";
import {TramiteSecurityService} from "./tramite-security.service";
import {TramiteSharedService} from "./tramite-shared.service";
import {MsalService} from "@azure/msal-angular";
import {PopupService} from "./popup.service";
import {RequestService} from "./request.service";

@Injectable({ providedIn: 'root' })
export class AuthService {
  urlInter = 'https://login-appservice-back2.azurewebsites.net/auth/';
  public currentUserSubject: BehaviorSubject<SSOAuthResponse>;
  public currentUser: Observable<SSOAuthResponse>;
  private users!: AuthModel[];
  private userByDefault!: SSOAuthResponse;
  counterChangesRefresh = 1;
  response = false;

  subSolicitante = new BehaviorSubject(null);
  subSolicitante$ = this.subSolicitante.asObservable();

  tipoPersonaSolicitante = new BehaviorSubject(null);
  tipoPersonaSolicitante$ = this.tipoPersonaSolicitante.asObservable();

  private noInterceptorHttpClient: HttpClient;

  constructor(private http: HttpClient,
              handler: HttpBackend,
              private router: Router,
              private tramiteSecurityService: TramiteSecurityService,

              private  alert : PopupService,
              private msalService : MsalService,
              private requestService : RequestService,
              private tramiteSharedService: TramiteSharedService) {
    this.users = [];

    this.currentUserSubject = new BehaviorSubject<SSOAuthResponse>(JSON.parse(String(localStorage.getItem('currentUser'))));
    this.currentUser = this.currentUserSubject.asObservable();

    console.log(this.currentUser);
    this.noInterceptorHttpClient = new HttpClient(handler);
  }

  public get currentUserValue(): SSOAuthResponse {
    return this.currentUserSubject.value;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  isCurrentUserAndNotTokenExpired(): boolean {
    try {
      const currentUser = this.currentUserValue;
      const expiry = JSON.parse(atob(currentUser.access_token.split('.')[1])).exp;
      console.log('Expity', expiry);
      const localTime = Math.floor((new Date).getTime() / 1000);
      console.log('localtime***', localTime);
      if (localStorage.getItem('dateIn') === undefined || localStorage.getItem('dateIn') === null) {
        localStorage.setItem('dateIn', new Date().toString());
      }
      const before = new Date(localStorage.getItem('dateIn'));
      let dataRefresh = 0;
      if (!isNaN(currentUser.refresh_expires_in)) {
        dataRefresh = currentUser.refresh_expires_in;
      }
      let refresh = (0.9 * dataRefresh) / 60;
      const timeReal = new Date();
      const diff = Math.round(((timeReal.getTime() - before.getTime()) / 60000) / this.counterChangesRefresh);

      let valueExpire=0;
      if(localStorage.getItem('currentUser'))
      {
        if(JSON.parse(localStorage.getItem('currentUser')).expires_in)
        {
          valueExpire=JSON.parse(localStorage.getItem('currentUser')).expires_in;
        }
      }
       const compareRefresh = 0.9 * (valueExpire / 60);
      if (refresh <= diff) {
        console.log('Caduco el refesh principal');
        this.cleanAll();
        return false;
      } else if (compareRefresh <= diff) {

        return this.response;
      } else {


        return true;
      }
    } catch (e) {
      return false;
    }
  }


  async setCurrentUser(claims: any, idToken?: any, refreshToken?: any): Promise<SSOAuthResponse> {

    let codeVentanillaPerson: number;
    let personVentanilla: any;


    await lastValueFrom(this.tramiteSecurityService.getCodeVentanillaByIdUser(this.currentUserValue.sub).pipe(
      switchMap(resp2 => {

        codeVentanillaPerson = resp2.data;
        console.log(codeVentanillaPerson);
        return this.tramiteSharedService.getInfoUserByIdCodeVentanilla(codeVentanillaPerson);
      })
    )).then(resp => {

      personVentanilla = resp.data;

    });

    const currentUser: SSOAuthResponse = {
      access_token: idToken ? idToken : "token",
      refresh_token: refreshToken ? refreshToken : "refresh_token",
      refresh_expires_in: 10000,
      notBeforePolicy: 100000,
      id_token: "id_token",
      session_state: "session_state",
      expires_in: this.currentUserValue.expires_in,
      rol: "solicitante",
      sid: "sid",
      email_verified: true,
      name: this.currentUserValue.name,
      preferred_username: this.currentUserValue.preferred_username,
      given_name: this.currentUserValue.given_name,
      family_name: this.currentUserValue.family_name,
      email: this.currentUserValue.email,
      secondName: this.currentUserValue.secondName,
      secondSurname: this.currentUserValue.secondSurname,
      phone: this.currentUserValue.phone,
      extension_TipodeIdentificacion: this.currentUserValue.extension_TipodeIdentificacion,
      documentNumber: this.currentUserValue.documentNumber,
      extension_Juridico: this.currentUserValue.extension_Juridico,
      aud: this.currentUserValue.aud,
      auth_time: this.currentUserValue.auth_time,
      // city: claims['city'],
      emails: this.currentUserValue.emails,
      // jobTitle: claims['jobTitle'],
      sub: this.currentUserValue.sub,
      codeVentanilla: codeVentanillaPerson,
      userVentanilla: personVentanilla
    };

    this.currentUserSubject.next(currentUser);
    return lastValueFrom(of(currentUser));
  }


  internalLogin(payload: any): Observable<any> { //Authentication
    return this.http.post<any>(`${ this.urlInter }login`, {
        email: payload.userName,
        pwd: payload.password
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
    ).pipe(map(res => {
      let decodedToken = this.getDecodedAccessToken(res.accessToken)
      let parsedRoles = JSON.parse(decodedToken.access)
      let procedure16Roles = parsedRoles.filter((element) => {
        return element.ProcedureName == "Tramite 16"
      })
      let role = procedure16Roles[0].RolesDto[0].Rol;

      const currentUser = {
        access_token: res.accessToken,
        sub: decodedToken.sub,
        refresh_token: res.refreshToken,
        refresh_expires_in: res.refreshTokenExpiresIn,
        notBeforePolicy: res.notBeforePolicy ? res.notBeforePolicy : '',
        scope: res.scope ? res.scope : '',
        id_token: res.id_token ? res.id_token : '',
        token_type: res.tokenType,
        session_state: res.session_state ? res.session_state : '',
        expires_in: res.accessTokenExpiresIn,
        rol: role.toLowerCase(),
        sid: res.sid ? res.sid : '',
        email_verified: res.email_verified ? res.email_verified : false,
        name: `${ decodedToken.name } ${ decodedToken.surname }`,
        preferred_username: decodedToken.username,
        given_name: decodedToken.name,
        family_name: decodedToken.surname,
        email: decodedToken.email,
        userId: res.userId,
      };
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      this.currentUserSubject.next(currentUser);
      return currentUser;
    }));
  }



  public LoginB2CAzure(): any {
    this.msalService.loginPopup().subscribe(
      res =>
      {
        console.log(res);
        // @ts-ignore

        this.requestService.getRoleByIdUser(res.account.idTokenClaims.oid).subscribe(role =>
        {
          if(role.data!=null)
          {
            let rol = role.data[0].value.toLowerCase();
            if(rol=="ciudadano")
            {
              rol='solicitante';
            }
            if(rol=="funcionario")
            {
              rol='validador';
            }

            const currentUser = {
              access_token: res.idToken,
              sub: res.account.idTokenClaims.sub,
              refresh_token: res.account.idTokenClaims.auth_time+"",
              refresh_expires_in: res.account.idTokenClaims.exp,
              notBeforePolicy: res.account.idTokenClaims.iat ?? 0,
              scope: this.convertirstring(res.scopes[0]) ,
              id_token:this.convertirstring( res.idToken) ,
              token_type: res.tokenType,
              session_state: this.convertirstring(res.state) ,
              expires_in: res.account.idTokenClaims.exp,
              rol: rol,
              sid: '',
              email_verified: role.data[0]?.email ? role.data[0].email : false,
              name: res.account.idTokenClaims.name,
              preferred_username: res.account.username,
              given_name:res.account.idTokenClaims.given_name+"",
              family_name: res.account.idTokenClaims.family_name+"",
              email: role.data[0].email,
              userId: res.account.idTokenClaims.oid,
            };
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.currentUserSubject.next(currentUser);
            this.alert.infoAlert('Bienvenid@ a Secretaría de Salud Distrital de Bogotá.', 4000);
            this.router.navigate([ 'inbox' ]);
          }
          else
          {
            this.alert.errorAlert('Usuario no registrado por el flujo', 4000);
          }

        });


      }
    )

  }

  private convertirstring(value:any)
  {
    if (value)
    {
      return value
    }
    return"";
  }

  public loginventanilla()
  {
    const ventanilla=JSON.parse( localStorage.getItem('datosventanilla'));

    // @ts-ignore

    this.requestService.getRoleByIdUser(ventanilla.oid).subscribe(role =>
    {
      if(role.data!=null)
      { let rol = role.data[0].value.toLowerCase();
        if(rol=="ciudadano")
        {
          rol='solicitante';
        }
        if(rol=="funcionario")
        {
          rol='validador';
        }
        const currentUser = {
          access_token: ventanilla.flujo.idToken,
          sub: ventanilla.flujo.account.idTokenClaims.sub,
          refresh_token: ventanilla.flujo.account.idTokenClaims.auth_time+"",
          refresh_expires_in: ventanilla.flujo.account.idTokenClaims.exp,
          notBeforePolicy: ventanilla.flujo.account.idTokenClaims.iat ?? 0,
          scope: this.convertirstring(ventanilla.flujo.scopes[0])+'' ,
          id_token: this.convertirstring(ventanilla.flujo.idToken) +'',
          token_type: ventanilla.flujo.tokenType,
          session_state: this.convertirstring(ventanilla.flujo.state) + '',
          expires_in: ventanilla.flujo.account.idTokenClaims.exp,
          rol: rol ,
          sid: '',
          email_verified: role.data[0].email ? role.data[0].email : false,
          name: ventanilla.flujo.account.idTokenClaims.name,
          preferred_username: ventanilla.flujo.account.username,
          given_name:ventanilla.flujo.account.idTokenClaims.given_name+"",
          family_name: ventanilla.flujo.account.idTokenClaims.family_name+"",
          email: role.data[0].email,
          userId: ventanilla.flujo.account.idTokenClaims.oid,
        };
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.currentUserSubject.next(currentUser);
        this.alert.infoAlert('Bienvenid@ a Secretaría de Salud Distrital de Bogotá.', 4000);
        this.router.navigate([ 'inbox' ]);
      }
      else
      {
        this.alert.errorAlert('Usuario no registrado por el flujo', 4000);
      }

    });
  }




  externalRefresh(refresh_token: any, usuario: any): Observable<any> { //Authentication
    let body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refresh_token);
    body.set('client_id', "2f9ffa76-cca3-40ab-b95f-ac882b8e5b9a");
    body.set('redirect_uri', "/");

    return this.http.post<any>(`https://soaintje.b2clogin.com/soaintje.onmicrosoft.com/B2C_1_iniciosesion/oauth2/v2.0/token?` + body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        })
      }
    ).pipe(map(res => {
      const currentUser = {
        access_token: res.id_token ? res.id_token : '',
        expires_in: res.id_token_expires_in ? res.id_token_expires_in : '',
        documentNumber: usuario.documentNumber,
        sub: usuario.sub ? usuario.sub : '',
        refresh_token: res.refresh_token,
        refresh_expires_in: res.refresh_token_expires_in,
        notBeforePolicy: res.not_before ? res.not_before : '',
        scope: res.scope ? res.scope : '',
        id_token: "id_token",
        rol: usuario.rol ? usuario.rol : 'solicitante',
        sid: usuario.sid ? usuario.sid : '',
        email_verified: usuario.email_verified ? usuario.email_verified : false,
        name: usuario.name ? usuario.name : '',
        preferred_username: usuario.preferred_username ? usuario.preferred_username : '',
        given_name: usuario.given_name ? usuario.given_name : '',
        family_name: usuario.family_name ? usuario.family_name : '',
        email: usuario.preferred_username,
        session_state: "id_token"
      };
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      this.currentUserSubject.next(currentUser);
      return currentUser;
    }));
  }

  internalLogout(payload: any): Observable<any> { //Authentication
    return this.http.post<any>(`${ this.urlInter }logout`, {
        tokenValue: payload.tokenValue ? payload.tokenValue : "abc092a9eb20409bb77ff3fa40774a9b",
        userID: payload.userID ? payload.userID : "b14766fd-cda2-4254-aa9b-b072dd3bc2d5"
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
    );
  }

  internalUsersByRole(rol: string): Observable<any> {
    return this.http.post<any>(`${ this.urlInter }users-by-role/${ rol }`,
      {
        "ClientId": "e6fae8b8-c8a2-47ac-8244-df3062897195"
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Cookie': '.cookhouse=login-appservice-back2.azurewebsites.net'
        })
      }
    );
  }

  externalB2CAuth(): Observable<any> {
    return this.http.post<any>(`https://login-appservice-back2.azurewebsites.net/auth/users-token`,
      { "clientId": "19c516fc-e0ac-4422-a041-c1f119d0b11a" },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
    );
  }

  externalGetUserByRole(token, user): Observable<any> {
    const params = '$select=id,displayName,givenName,surname,emails,identities'
    const extensionParams = 'extension_78429ced64144f2ca1747107feb82edd_TelefonoCelular,extension_78429ced64144f2ca1747107feb82edd_NumerodeIdentificacion,extension_78429ced64144f2ca1747107feb82edd_SegundoNombre,extension_78429ced64144f2ca1747107feb82edd_SegundoApellido, extension_78429ced64144f2ca1747107feb82edd_TipodeIdentificacion,extension_78429ced64144f2ca1747107feb82edd_Juridico'
    return this.noInterceptorHttpClient.get<any>(`https://graph.microsoft.com/v1.0/users/${ user }?${ params },${ extensionParams }`,
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${ token }`
        })
      }
    );
  }

  cleanAll() {
    console.log('Saliendo !!!');

    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigateByUrl(ROUTES_PATH.SignIn);
  }
}
