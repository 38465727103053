import { Injectable } from '@angular/core';

import { BehaviorSubject  } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AddressService {

  setAddress = new BehaviorSubject(null);
  getAddress$ = this.setAddress.asObservable();

  consultAddress = new BehaviorSubject(false);
  consultAddress$ = this.consultAddress.asObservable();

  setServiceAddress = new BehaviorSubject(null);
  getServiceAddress$ = this.setServiceAddress.asObservable();



}
