import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EntityService {
  entitySource = new BehaviorSubject(null);
  entityTarget$ = this.entitySource.asObservable();

  constructor(private http: HttpClient) {
  }


}
