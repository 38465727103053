// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrlBack: 'https://localhost:5001',
  //apiUrlBack: 'https://app-sds-exhumacion-dev-eastus.azurewebsites.net',
  apiUrlBack: 'https://apm-aeu-sds-dev-shared.azure-api.net/Tramites-Exhumacion',
  apiManager: 'https://apm-aeu-sds-dev-shared.azure-api.net/filemanager',

  PROCEDURE_BLOB_URI:'https://apm-aeu-sds-dev-shared.azure-api.net/filemanager/api/v1',

  TRAMITE_SHARED_URL: 'https://apm-aeu-sds-dev-shared.azure-api.net/tramites-shared/api',
  PROCEDURE_SECURITY_URI: 'https://apm-aeu-sds-dev-shared.azure-api.net/security/api/v2/Security',
  TRAMITE_SECURITY_URL: 'https://apm-aeu-sds-dev-shared.azure-api.net/security/api/v2/Security',
  TRAMITE_NOTIFICATIONS_URI: 'https://apm-aeu-sds-dev-shared.azure-api.net/notifications/api/v1',

  TRAMITE_API_URL: 'https://w-logic-request.azurewebsites.net/api',

  procedureName : 'Licencia de Exhumación para Cadáveres',
  procedureFullName : 'Licencia de Exhumación para Cadáveres',
  procedureDescription : 'Autorización para extraer cadáveres, restos humanos y restos óseos del lugar de inhumación, previa orden judicial o administrativa para los efectos funerarios o legales una vez se haya cumplido el tiempo mínimo de permanencia establecido por la Ley. Nota: El trámite está dirigido para las exhumaciones de muertes ocurridas en la ciudad de Bogotá D.C. Cualquier inquietud en relación al trámite agradezco comunicarse al correo electrónico contactenos@saludcapital.gov.co.',
  idHTMLFormatInformationData : "6C4CBB4F-4A7A-4A73-9475-2E5EC1135D32",
  numProcedure : '6',
  nomDocCertificadoExistencia: 'CertificadoExistencia',
  tiempoDeAtencion : 30,
  containerBlob : 'exhumacion',
  KEY_RECAPTCHA_SITE_WEB: '6Lel3eMmAAAAALkg4o93dldoWIAP50FH4KE-E3ii',

  VUDTS_URL: 'https://dev.ventanillavirtualtramites.saludcapital.gov.co',
  VUDTS_URL_LOCAL: 'localhost:4200',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
