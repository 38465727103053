import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable} from "rxjs";
import { environment } from "../../../environments/environment";

import { NameValue } from '../models/nameValue.model';
import { Requirement } from '../models/requirement.model';
import { Response } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class TramiteSharedService {

  constructor(private http: HttpClient) {
  }

  //API URL BACK
  getListConstants(id:string):Observable<NameValue[]> {
    return this.http.get<Response>(`${environment.apiUrlBack}/api/Constant/GetConstantByIdFather/${id}`).pipe(map(resp => {
      return resp.data;
    }));
  }


  getRequirementsBySection(id : string):Observable<Requirement[]> {
    return this.http.get<Response>(`${environment.apiUrlBack}/api/Document/GetDocumentRequirementBySection/${id}`).pipe(map(resp => {
      return resp.data;
    }));
  }

  updateStatusRequest(json : any):Observable<any> {
    return this.http.put<Response>(`${environment.apiUrlBack}/api/Request/UpdateStateRequest`, json).pipe(map(resp => {
      return resp.data;
    }));
  }




  //API SHARED

  getPaises() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Pais/GetPais`).pipe(map(resp => {
      return resp.data.map(pais => {
        return {
          name : pais.nombre,
          value : pais.idPais
        }
      });
    }));
  }

  getDepartments() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Departamento/GetDepartamento`).pipe(map(resp => {
      return resp.data.map(departamento => {
        return {
          name : departamento.descripcion,
          value : departamento.idDepartamento.toString()
        }
      });
    }));
  }

  getCities(idDepartment : string) {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Municipio/GetMunicipioByIdDepartamento/${idDepartment}`).pipe(map(resp => {
      return resp.data.map(city => {
        return {
          name : city.descripcion,
          value : city.idMunicipio.toString()
        }
      });
    }));
  }


  getZones() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/SubRed/GetSubRed`);
  }

  getLocalities() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Localidad/GetAllLocalidad`);
  }

  getUpz() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Upz/GetUpz`);
  }

  getBarrios() {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Barrio/GetBarrios`);
  }

  getBarriosByUpz(idUpz) {
    return this.http.get<any>(`${ environment.TRAMITE_SHARED_URL }/v1/Barrio/GetBarrioByIdUpz/${ idUpz }`);
  }

  /**
   * Obtiene la informacion de un usuario dado su codigo de ventanilla
   * @param idCode Codigo de ventanilla a buscar
   */
  getInfoUserByIdCodeVentanilla(idCode: any): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SHARED_URL }/v2/Persona/GetInfoUserById/${idCode}`);
  }
  /**
   * Obtiene lista de Tipos de Identificacion
   */
  getIdentificationType(): Observable<any>{
    return this.http.get(`${ environment.TRAMITE_SHARED_URL }/v1/TipoIdentificacion/GetTipoIdentificacion`);
  }

  /**
   * Obtiene la informacion de un usuario dado su codigo de ventanilla
   * @param idCode Codigo de ventanilla a buscar
   */
  getCodeVentanillabyiduser(idCode: any): Observable<any> {
    return this.http.get(`${ environment.PROCEDURE_SECURITY_URI }/GetCodeVentanillaByIdUser/${idCode}`);
  }
  /**
   * Guarda Datos Persona Natural
   */
  public updatePerson(data: any): Observable<any> {
    return this.http.put(`${environment.TRAMITE_SHARED_URL}/v2/Persona/UpdatePerson `, data);
  }

  /**
   * Valida si existe una licencia de Inhumación y
   * Obtiene la informacion de un fallecido de las bd de oracle y sql(azure)
   * @param numerolicencia Numero de licencia a buscar
   * @param fecha Fecha de la licencia a buscar
   */
  public getDeceasedInfo(numerolicencia:string, fecha: string): Observable<any> {
    return this.http.get(`${ environment.TRAMITE_SHARED_URL }/v1/CertificadoDefuncion/ValidateDefuncionByLicenciaAndFecha/${numerolicencia}/${fecha}`);
  }

  /**
   * Guarda Datos Persona Natural
   */
  public updateDeceseasedinfo(data: any): Observable<any> {
    return this.http.put(`${environment.TRAMITE_SHARED_URL}/v1/CertificadoDefuncion/UpdateDefuncion`,data);
  }

  /**
   * Obtiene las solicitudes con el mismo numero de id
   * @param id
   */
  getRemainingdays(fecha:any ,id: string) : Observable<any> {
    return this.http.get(`${environment.TRAMITE_SHARED_URL}/v1/Holiday/CalculateRemainingDays/${fecha}/${id}`);
  }

}
