<nav aria-label="breadcrumb">
  <ol class="breadcrumb gov-co-breadcrumb" style="margin-left:85px; display: flex; flex-wrap: wrap;">
    <li *ngFor="let breadcrumb of mapRoutes; let s = index; let last = last" class="breadcrumb-item">
      <a [routerLink]="breadcrumb?.url">
        {{ breadcrumb?.label }}
      </a>
      <span [style.padding-left]="'5px !important'"  *ngIf="!last"> > </span>
      <span class="vtab-section-label" *ngIf="lastSection && last && isInboxPath(breadcrumb)"> > {{lastSection}}</span>
    </li>
  </ol>
</nav>
